// src/reducers/rootReducer.ts
import { combineReducers } from "redux";
import userReducer from "./user-reducer";
import noteReducer from "./note-reducer";
import chatReducer from "./chat-reducer";

// Combine all reducers into a root reducer
export const rootReducer = combineReducers({
  user: userReducer,
  note: noteReducer,
  chat: chatReducer,
});
