import {
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useServices } from "../../util/service-provider";

const BetaListForm = () => {
  const theme = useTheme();
  const { userService } = useServices();

  const [formData, setFormData] = useState({
    email: "",
    comments: "",
  });

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);

    // Handle form submission logic here
    userService
      .joinBeta(formData.email, formData.comments)
      .then(() => {
        setSubmitted(true);
      })
      .catch(() => {
        setSubmitError(
          "That didn't work...please try again later or reach out on LinkedIn."
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const inputSx = {
    input: {
      py: 3.5,
    },
    mb: 2,
  };

  const formSx = {
    display: "flex",
    flexDirection: "column",
    m: {
      xs: 1,
      md: 2,
      lg: 4,
    },
    px: {
      xs: 1,
      md: 2,
      lg: 4,
    },
    py: 2,
  };

  return (
    <Container sx={{ backgroundColor: theme.palette.secondary.light }}>
      {submitted ? (
        <Box
          sx={{
            m: 5,
            mb: 6,
          }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Thanks for your interest!
          </Typography>

          <Typography variant="body1" sx={{ mt: 4, mx: 2 }}>
            Once the tool is ready for use by a wider audience, you'll be the
            first to be given access. Stay tuned!
          </Typography>
        </Box>
      ) : (
        <Box component="form" onSubmit={handleSubmit} sx={formSx}>
          <Typography variant="h5" sx={{ mb: 3 }}>
            Join the beta
          </Typography>

          <TextField
            label="Email"
            variant="outlined"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            sx={inputSx}
            required
          />

          <TextField
            label="Additional comments"
            placeholder="Anything you'd change or features you'd like to see?"
            variant="outlined"
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            sx={inputSx}
            multiline
            minRows={3}
            maxRows={4}
          />

          <Typography variant="subtitle2">
            Feel free to connect with me on&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/dongeric/"
            >
              LinkedIn
            </a>
            &nbsp;as well.
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            {submitting ? (
              <CircularProgress size={28} />
            ) : (
              <Button
                type="submit"
                variant="contained"
                size="medium"
                sx={{
                  backgroundColor: "secondary.light",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "secondary.main",
                  },
                }}
              >
                Confirm
              </Button>
            )}
          </Box>

          {submitError && (
            <Typography
              align="right"
              color="error"
              variant="body2"
              sx={{ mt: 1 }}
            >
              {submitError}
            </Typography>
          )}
        </Box>
      )}
    </Container>
  );
};

export default BetaListForm;
