export interface Note {
  id: string;
  pageNumber: number;
  content: string;
}

type NoteState = {
  currentNote: Note;
};

const initialState: NoteState = {
  currentNote: {
    id: "",
    pageNumber: 0,
    content: "",
  },
};

enum NoteActionTypes {
  SET_NOTE = "SET_NOTE",
  UPDATE_NOTE = "UPDATE_NOTE",
}

export interface NoteAction {
  type: NoteActionTypes;
  payload?: any;
}

export default function noteReducer(
  state = initialState,
  action: NoteAction
): NoteState {
  switch (action.type) {
    case NoteActionTypes.SET_NOTE:
      return {
        currentNote: action.payload,
      };
    case NoteActionTypes.UPDATE_NOTE:
      return {
        currentNote: {
          ...state.currentNote,
          content: action.payload,
        },
      };
    default:
      return state;
  }
}

export const setNote = (note: Note) => ({
  type: NoteActionTypes.SET_NOTE,
  payload: note,
});

export const updateNote = (content: string) => ({
  type: NoteActionTypes.UPDATE_NOTE,
  payload: content,
});
