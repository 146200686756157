import { AccountType, ChatLog, Page } from "../const/storage";
import { sendPostRequest } from "../util/services";

export interface UserLoginResponse {
  jwt: string;
  username: string;
  accountType: AccountType;
  currentPage?: Page;
  recentChatLog?: ChatLog;
}

export interface ValidateSessionResponse {
  jwt: string;
  isValid: boolean;
}

class UserService {
  jwt?: string;

  async login(username: string): Promise<UserLoginResponse> {
    const loginUrl: string = process.env.REACT_APP_USER_LOGIN_URL!;

    const response = await sendPostRequest(loginUrl, { username });
    return response
      .json()
      .then((responseBody) => responseBody as UserLoginResponse);
  }

  // Fired when user closes the tab/browser or refreshes the page, so no need
  // to wait for a response.
  logout(jwt: string, username: string) {
    const logoutUrl: string = process.env.REACT_APP_USER_LOGOUT_URL!;
    navigator.sendBeacon(logoutUrl, JSON.stringify({ jwt, username }));
  }

  async validateSession(jwt: string): Promise<ValidateSessionResponse> {
    const validateSessionUrl: string =
      process.env.REACT_APP_VALIDATE_SESSION_URL!;

    const response = await sendPostRequest(validateSessionUrl, { jwt });
    return response
      .json()
      .then((responseBody) => responseBody as ValidateSessionResponse);
  }

  async joinBeta(email: string, comments?: string): Promise<Response> {
    const joinBetaUrl: string = process.env.REACT_APP_BETA_LIST_URL!;
    return sendPostRequest(joinBetaUrl, { email, comments });
  }
}

export default UserService;
