import { AccountType } from "../const/storage";

type UserState = {
  jwt: string;
  username: string;
  accountType: AccountType;
};

const initialState: UserState = {
  jwt: "",
  username: "",
  accountType: AccountType.DEMO,
};

enum UserActionTypes {
  UPDATE_USER = "UPDATE_USER",
}

export interface UserAction {
  type: UserActionTypes;
  payload?: any;
}

export default function userReducer(
  state = initialState,
  action: UserAction
): UserState {
  const payload = action.payload;
  switch (action.type) {
    case UserActionTypes.UPDATE_USER:
      return {
        jwt: payload.jwt,
        username: payload.username,
        accountType: payload.accountType,
      };
    default:
      return state;
  }
}

export const updateUser = (
  jwt: string,
  username: string,
  accountType: AccountType
) => ({
  type: UserActionTypes.UPDATE_USER,
  payload: { jwt, username, accountType },
});
