import { Icon, Paper, Typography, useTheme } from "@mui/material";
import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import { ChatLogAgent, ChatLogMessage } from "../../const/storage";
import "highlight.js/styles/kimbie-light.css";

interface ChatMessageProps {
  message: ChatLogMessage;
}

const ChatMessage = (props: ChatMessageProps) => {
  const theme = useTheme();

  const isUserMessage = props.message.agent === ChatLogAgent.USER;

  // User message.
  const userMessageStyle = {
    borderRadius: "20px",
    backgroundColor: theme.palette.secondary.light,
    maxWidth: "60%",
  };
  const userMessage = (
    <div className="flex justify-end">
      <Paper
        className="tr pv2 ph3 overflow-scroll"
        elevation={3}
        sx={userMessageStyle}
      >
        <Typography sx={{ textAlign: "left", wordBreak: "break-word" }}>
          {props.message.content}
        </Typography>
      </Paper>
    </div>
  );

  // Bot message.
  const botMessage = (
    <div className="flex">
      <Icon sx={{ width: 36, height: 36 }} className="mr3 mt2">
        <img
          src={`${process.env.PUBLIC_URL}/img/logo.png`}
          className="w-100 h-100"
          alt=""
        />
      </Icon>
      <Typography className="overflow-auto">
        <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
          {props.message.content}
        </ReactMarkdown>
      </Typography>
    </div>
  );

  const messageClassNames =
    "w-100 mv2 " + (isUserMessage ? "self-end" : "self-start");
  return (
    <div className={messageClassNames}>
      {isUserMessage ? userMessage : botMessage}
    </div>
  );
};

export default ChatMessage;
