import { createTheme } from "@mui/material/styles";
import { green, amber, red, grey } from "@mui/material/colors";

export const palette = {
  primary: {
    light: "#848F82",
    main: "#577871",
    dark: green[900],
    contrastText: grey[800],
  },
  secondary: {
    light: "#E7DFD1",
    main: "#C6B5A4",
    dark: amber[900],
  },
  error: {
    main: red[800],
  },
};

const theme = createTheme({
  palette,
});

export default theme;
