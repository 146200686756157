import { ChatLog } from "../const/storage";
import { sendPostRequest } from "../util/services";

export interface GetLastChatLogResponse {
  chatLog: ChatLog;
}

export type GetPreviousChatLogResponse = GetLastChatLogResponse;

export type CreateNewChatLogResponse = GetLastChatLogResponse;

export type SendChatMessageResponse = {
  timestamp: string;
  message: string;
};

class ChatService {
  jwt?: string;

  async getLastChatLog(username: string): Promise<GetLastChatLogResponse> {
    const getChatUrl: string = process.env.REACT_APP_GET_LAST_CHAT_LOG_URL!;

    const fetchUrl = new URL(getChatUrl);
    fetchUrl.searchParams.append("username", username);
    const response = await fetch(fetchUrl.toString());
    return response
      .json()
      .then((responseBody) => responseBody as GetLastChatLogResponse);
  }

  async getPreviousChatLog(
    username: string,
    chatLogId: string
  ): Promise<GetPreviousChatLogResponse> {
    const getPreviousUrl: string =
      process.env.REACT_APP_GET_PREVIOUS_CHAT_LOG_URL!;

    const fetchUrl = new URL(getPreviousUrl);
    fetchUrl.searchParams.append("username", username);
    fetchUrl.searchParams.append("chatLogId", chatLogId);

    const response = await fetch(fetchUrl.toString());
    return response
      .json()
      .then((responseBody) => responseBody as GetPreviousChatLogResponse);
  }

  async createNewChatLog(username: string): Promise<CreateNewChatLogResponse> {
    const createChatUrl: string =
      process.env.REACT_APP_CREATE_NEW_CHAT_LOG_URL!;

    const response = await sendPostRequest(createChatUrl, {
      jwt: this.jwt,
      username,
    });
    return response
      .json()
      .then((responseBody) => responseBody as CreateNewChatLogResponse);
  }

  async sendChatMessage(
    username: string,
    chatLogId: string,
    message: string
  ): Promise<SendChatMessageResponse> {
    const sendChatUrl: string = process.env.REACT_APP_SEND_CHAT_MESSAGE_URL!;

    const response = await sendPostRequest(sendChatUrl, {
      jwt: this.jwt,
      username,
      chatLogId,
      message,
    });
    return response
      .json()
      .then((responseBody) => responseBody as SendChatMessageResponse);
  }
}

export default ChatService;
