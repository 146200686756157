import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./reducers";

// Create the Redux store with the root reducer
export const store = configureStore({
  reducer: rootReducer,
});

// Define RootState and AppDispatch for type inference
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
