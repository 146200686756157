import {
  AppBar,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useServices } from "../../util/service-provider";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setNote, updateNote } from "../../reducers/note-reducer";
import { CreateNewNoteResponse } from "../../services/notes-service";
import "./notes.css";
import { AccountType } from "../../const/storage";
import { useSmallScreenQuery } from "../../util";

// Trigger auto-save if user stops typing for 2 seconds.
export const TYPING_TIMEOUT = 2000;

const Notes = () => {
  const theme = useTheme();
  const { notesService } = useServices();
  const dispatch = useDispatch();

  const {
    containerStyle,
    appBarStyle,
    textContainerStyle,
    textStyle,
    textInputProps,
  } = getStyles(theme);

  const currentDate = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  });

  // Keep notes in sync with root state.
  const stateNote = useSelector((state: RootState) => state.note.currentNote);
  useEffect(() => {
    setCurrentText(stateNote.content);
  }, [stateNote.content]);

  const [statusText, setStatusText] = useState("");
  const [isSavingNotes, setIsSavingNotes] = useState(false);

  // Changes to notes. Handles auto-save.
  const [currentText, setCurrentText] = useState("");
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (currentText !== stateNote.content) {
      timeout = setTimeout(() => {
        setStatusText("Saving changes...");

        // Send save request.
        setIsSavingNotes(true);
        dispatch(updateNote(currentText));
        notesService
          .saveNotes(stateNote.id, currentText)
          .then(() => {
            setLastSavedDate(new Date());
          })
          .catch(() => {
            setStatusText("There was an issue saving your notes");
          })
          .finally(() => {
            setIsSavingNotes(false);
          });
      }, TYPING_TIMEOUT);
      setCurrentTimeout(timeout);
    }

    return () => {
      clearTimeout(timeout);
      setCurrentTimeout(null);
    };
  }, [currentText, dispatch, notesService, stateNote]);

  // Changes to last saved date. Handles updating the text on page.
  const [lastSavedDate, setLastSavedDate] = useState<Date | null>(null);
  useEffect(() => {
    if (lastSavedDate) {
      const lastSavedTime = lastSavedDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      setStatusText(`Last saved at ${lastSavedTime}`);
    }
  }, [lastSavedDate]);

  // New page button
  const user = useSelector((state: RootState) => state.user);
  const [isCreatingNewPage, setIsCreatingNewPage] = useState(false);
  const isNewPageButtonDisabled =
    user.accountType === AccountType.DEMO ||
    stateNote.content === "" ||
    isSavingNotes ||
    isCreatingNewPage;

  const onNewPage = () => {
    // Clear any existing auto-save timers.
    if (currentTimeout) {
      clearTimeout(currentTimeout);
      setCurrentTimeout(null);
    }

    setIsCreatingNewPage(true);
    notesService
      .createNewNote(user.username)
      .then((response: CreateNewNoteResponse) => {
        dispatch(
          setNote({
            id: response.page.id,
            pageNumber: response.page.pageNumber,
            content: response.page.content,
          })
        );
        setStatusText("");
      })
      .catch(() => {
        alert("Error creating new page. Please try again later.");
      })
      .finally(() => {
        setIsCreatingNewPage(false);
      });
  };

  const isSmallScreen = useSmallScreenQuery();
  let newPageButtonComponentClassnames;
  if (user.accountType === AccountType.DEMO) {
    newPageButtonComponentClassnames = "mt3 pointer pv3";
  } else {
    newPageButtonComponentClassnames =
      "self-center mt3 pointer pv3 " + (isSmallScreen ? "w-80" : "w-40");
  }
  const newPageButtonComponent = (
    <Button
      className={newPageButtonComponentClassnames}
      disabled={isNewPageButtonDisabled}
      onClick={onNewPage}
    >
      {isCreatingNewPage ? (
        <CircularProgress size={20} />
      ) : (
        <Typography>+ New page</Typography>
      )}
    </Button>
  );

  const containerClassNames =
    "walkthrough-1 pt4 ph4 pb2 relative " + (isSmallScreen ? "ma2" : "ma3");
  return (
    <Paper sx={containerStyle} className={containerClassNames} elevation={10}>
      <AppBar position="static" sx={appBarStyle} className="nt4 nl4">
        <Toolbar className="flex justify-between">
          <Typography
            data-testid="currentDate"
            variant="h6"
            sx={{ color: theme.palette.primary.main }}
          >
            {currentDate}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {statusText}
          </Typography>
        </Toolbar>
      </AppBar>

      <div style={textContainerStyle} className="notes-field pt2">
        <TextField
          multiline
          data-testid="notesField"
          variant="standard"
          fullWidth
          placeholder="Say anything..."
          sx={textStyle}
          slotProps={textInputProps}
          value={currentText}
          onChange={(e) => setCurrentText(e.target.value)}
        />
      </div>

      {user.accountType === AccountType.DEMO ? (
        <Tooltip title="Unable to create new pages for this demo">
          <span className="w-40 self-center flex justify-center">
            {newPageButtonComponent}
          </span>
        </Tooltip>
      ) : (
        newPageButtonComponent
      )}

      <div
        className="absolute right-0 bottom-0 pb3"
        style={{ paddingRight: "1.2rem" }}
      >
        <Typography
          className="walkthrough-3"
          sx={{ color: theme.palette.primary.main }}
        >
          #{stateNote.pageNumber}
        </Typography>
      </div>
    </Paper>
  );
};

const getStyles = (theme: Theme) => ({
  containerStyle: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.light,
    display: "flex",
    flexDirection: "column",
    width: {
      xs: "100%",
      sm: "100%",
      md: "100%",
      lg: "50%",
    },
  },

  appBarStyle: {
    width: "calc(100% + 4rem)",
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.secondary.light,
    borderRadius: "4px",
  },

  textContainerStyle: {
    display: "flex",
    height: "100%",
    overflow: "scroll",
  },

  textStyle: {
    // Stretches text field to full container height.
    flexGrow: 1,
    paddingRight: "0.5rem",
  },

  textInputProps: {
    input: {
      disableUnderline: true,
      sx: {
        // Stretches text field to full container height.
        height: "100%",
        alignItems: "start",
      },
      inputProps: {
        maxLength: 50000,
      },
    },
  },
});

export default Notes;
