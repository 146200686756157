import {
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForward";
import React, { useState } from "react";
import { useServices } from "../../util/service-provider";
import { useDispatch } from "react-redux";
import { updateUser } from "../../reducers/user-reducer";
import { UserLoginResponse } from "../../services/user-service";
import { setNote } from "../../reducers/note-reducer";
import { Dispatch } from "redux";
import NotesService, {
  CreateNewNoteResponse,
} from "../../services/notes-service";
import { addChatHistory, setCurrentChatLog } from "../../reducers/chat-reducer";
import ChatService from "../../services/chat-service";

const Login = () => {
  const theme = useTheme();
  const { userService, notesService, chatService } = useServices();

  // Specify type here so the objectFit property works.
  const bgImgStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    objectFit: "cover",
    zIndex: -1,
  };

  const loginWindowProps = {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.light,
    width: {
      xs: "90%",
      sm: "60%",
      md: "50%",
      lg: "30%",
    },
  };

  const loginInputProps = {
    input: {
      className: "ph1 pv2",
      style: {
        color: theme.palette.primary.contrastText,
      },
    },
  };

  const dispatch = useDispatch();

  // Handle input changes
  const [username, setUsername] = useState("");
  const onUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginError(false);
    setLoginErrorText("");
    setUsername(event.target.value);
  };

  // Login
  const [loginPending, setLoginPending] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorText, setLoginErrorText] = useState("");
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onLogin();
    }
  };
  const onLogin = () => {
    if (username) {
      setLoginPending(true);
      userService
        .login(username)
        .then((response: UserLoginResponse) => {
          // Successful login
          setLoginError(false);
          setLoginErrorText("");

          chatService.jwt = response.jwt;
          notesService.jwt = response.jwt;
          userService.jwt = response.jwt;

          return handleLoginResponse(
            dispatch,
            notesService,
            chatService,
            response
          );
        })
        .then((response: UserLoginResponse) => {
          dispatch(
            updateUser(response.jwt, response.username, response.accountType)
          );
        })
        .catch((error) => {
          setLoginError(true);
          setLoginErrorText(error.message);
        })
        .finally(() => {
          setLoginPending(false);
        });
    }
  };

  return (
    <div className="h-100 flex justify-center items-center">
      <img
        src={`${process.env.PUBLIC_URL}/img/login-bg.webp`}
        style={bgImgStyle}
        alt=""
      />

      <Paper elevation={10} className="pa4" sx={loginWindowProps}>
        <Typography variant="h5">What's your name?</Typography>
        <div className="pt3 flex items-center">
          <TextField
            className="w-100 mt3"
            placeholder="Enter 'demo' for demo mode"
            slotProps={loginInputProps}
            value={username}
            onChange={onUsernameChange}
            onKeyDown={onKeyDown}
            error={loginError}
            helperText={loginErrorText}
          />
          <Button
            color="primary"
            sx={{ paddingLeft: "1rem", marginLeft: "0.5rem" }}
            startIcon={
              loginPending ? (
                <CircularProgress size={24} />
              ) : (
                <ArrowForwardOutlinedIcon style={{ fontSize: 38 }} />
              )
            }
            onClick={onLogin}
            disabled={loginPending || !username}
          ></Button>
        </div>
      </Paper>
    </div>
  );
};

const handleLoginResponse = (
  dispatch: Dispatch,
  notesService: NotesService,
  chatService: ChatService,
  response: UserLoginResponse
): Promise<UserLoginResponse> => {
  // Update current page in state or create a new one.
  // Update latest chat log in state.
  const apiRequests: Promise<any>[] = [];
  if (response.currentPage) {
    dispatch(
      setNote({
        id: response.currentPage.id,
        pageNumber: response.currentPage.pageNumber,
        content: response.currentPage.content,
      })
    );
  } else {
    apiRequests.push(
      notesService
        .createNewNote(response.username)
        .then((response: CreateNewNoteResponse) => {
          dispatch(
            setNote({
              id: response.page.id,
              pageNumber: response.page.pageNumber,
              content: response.page.content,
            })
          );
        })
    );
  }

  const todayDate = new Date();
  const chatLogDate = new Date(response.recentChatLog?.date || 0);
  if (dateToDateString(todayDate) === dateToDateString(chatLogDate)) {
    // Is today's chat log.
    dispatch(setCurrentChatLog(response.recentChatLog!));
  } else if (response.recentChatLog) {
    // Is a chat log from previous day.
    dispatch(addChatHistory(response.recentChatLog));
  }

  return Promise.all(apiRequests).then(() => response);
};

const dateToDateString = (date: Date): string =>
  `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`;

export default Login;
