import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline, GlobalStyles, ThemeProvider } from "@mui/material";
import theme from "./util/theme";
import "tachyons";
import { ServicesProvider } from "./util/service-provider";
import { Provider } from "react-redux";
import { store } from "./store";

const rootElement = document.getElementById("root") as HTMLElement;
rootElement.style.height = "100%";
const root = ReactDOM.createRoot(rootElement);

const globalStyle = {
  body: {
    backgroundColor: theme.palette.secondary.main,
    height: "100vh",
  },
};

root.render(
  <React.StrictMode>
    <CssBaseline />
    <GlobalStyles styles={globalStyle} />

    <ThemeProvider theme={theme}>
      <ServicesProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </ServicesProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
