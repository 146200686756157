import { Box, Divider, Typography, useTheme } from "@mui/material";

interface DateDividerProps {
  dateString: string;
}

const DateDivider = (props: DateDividerProps) => {
  const theme = useTheme();

  return (
    <Box className="flex items-center">
      <Divider sx={{ flexGrow: 1, marginLeft: "20%" }} />
      <Typography
        variant="subtitle2"
        color={theme.palette.primary.dark}
        sx={{ mx: "1rem" }}
      >
        {props.dateString}
      </Typography>
      <Divider sx={{ flexGrow: 1, marginRight: "20%" }} />
    </Box>
  );
};

export default DateDivider;
