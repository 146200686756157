export interface Page {
  id: string;
  username: string;
  pageNumber: number;
  createTime: string;
  content: string;
}

export interface ChatLog {
  id: string;
  date: string;
  messages: ChatLogMessage[];
}

export interface ChatLogMessage {
  timestamp: string;
  agent: ChatLogAgent;
  content: string;
}

export enum ChatLogAgent {
  USER = "USER",
  BOT = "BOT",
}

export enum AccountType {
  DEMO = "DEMO",
  BASIC = "BASIC",
}
