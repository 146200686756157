import { ChatLog, ChatLogAgent } from "../const/storage";

type ChatState = {
  currentChatLog: ChatLog;
  chatHistory: ChatLog[];
};

const initialState: ChatState = {
  currentChatLog: {
    id: "",
    date: "",
    messages: [],
  },
  chatHistory: [],
};

enum ChatActionTypes {
  SET_CURRENT_CHAT_LOG = "SET_CURRENT_CHAT_LOG",
  ADD_CHAT_HISTORY = "ADD_CHAT_HISTORY",
  SEND_MESSAGE = "SEND_MESSAGE",
  RECEIVE_MESSAGE = "RECEIVE_MESSAGE",
}

export interface ChatAction {
  type: ChatActionTypes;
  payload?: any;
}

export default function chatReducer(
  state = initialState,
  action: ChatAction
): ChatState {
  switch (action.type) {
    case ChatActionTypes.SET_CURRENT_CHAT_LOG:
      return {
        ...state,
        currentChatLog: action.payload,
      };
    case ChatActionTypes.ADD_CHAT_HISTORY:
      return {
        ...state,
        chatHistory: [action.payload, ...state.chatHistory],
      };
    case ChatActionTypes.SEND_MESSAGE:
      return addMessageToChat(
        state,
        new Date(),
        ChatLogAgent.USER,
        action.payload
      );
    case ChatActionTypes.RECEIVE_MESSAGE:
      return addMessageToChat(
        state,
        new Date(action.payload.timestamp),
        ChatLogAgent.BOT,
        action.payload.message
      );
    default:
      return state;
  }
}

const addMessageToChat = (
  state: ChatState,
  timestamp: Date,
  agent: ChatLogAgent,
  content: string
): ChatState => {
  return {
    ...state,
    currentChatLog: {
      ...state.currentChatLog,
      messages: [
        ...state.currentChatLog.messages,
        {
          timestamp: timestamp.toISOString(),
          agent,
          content,
        },
      ],
    },
  };
};

export const setCurrentChatLog = (currentChatLog: ChatLog) => ({
  type: ChatActionTypes.SET_CURRENT_CHAT_LOG,
  payload: currentChatLog,
});

export const addChatHistory = (chatLog: ChatLog) => ({
  type: ChatActionTypes.ADD_CHAT_HISTORY,
  payload: chatLog,
});

export const sendMessage = (message: string) => ({
  type: ChatActionTypes.SEND_MESSAGE,
  payload: message,
});

export const receiveMessage = (timestamp: string, message: string) => ({
  type: ChatActionTypes.RECEIVE_MESSAGE,
  payload: {
    timestamp,
    message,
  },
});
