import { createContext, useContext } from "react";
import NotesService from "../services/notes-service";
import UserService from "../services/user-service";
import ChatService from "../services/chat-service";

interface ServicesContextType {
  notesService: NotesService;
  userService: UserService;
  chatService: ChatService;
}

const ServicesContext = createContext<ServicesContextType | null>(null);

export const ServicesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const notesService = new NotesService();
  const userService = new UserService();
  const chatService = new ChatService();

  return (
    <ServicesContext.Provider
      value={{ notesService, userService, chatService }}
    >
      {children}
    </ServicesContext.Provider>
  );
};

export const useServices = (): ServicesContextType => {
  const context = useContext(ServicesContext);
  if (!context) {
    throw new Error("useServices must be used within a ServicesProvider");
  }
  return context;
};
