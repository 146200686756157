import { Page } from "../const/storage";
import { sendPostRequest } from "../util/services";

export interface CreateNewNoteResponse {
  page: Page;
}

class NotesService {
  jwt?: string;

  async createNewNote(username: string): Promise<CreateNewNoteResponse> {
    const createUrl: string = process.env.REACT_APP_CREATE_NEW_PAGE_URL!;

    const response = await sendPostRequest(createUrl, {
      jwt: this.jwt,
      username,
    });
    return response
      .json()
      .then((responseBody) => responseBody as CreateNewNoteResponse);
  }

  async saveNotes(id: string, content: string): Promise<void> {
    const updateUrl: string = process.env.REACT_APP_UPDATE_PAGE_URL!;

    await sendPostRequest(updateUrl, { jwt: this.jwt, id, content });
    return;
  }
}

export default NotesService;
