import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { useServices } from "../../util/service-provider";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  CreateNewChatLogResponse,
  GetPreviousChatLogResponse,
  SendChatMessageResponse,
} from "../../services/chat-service";
import { useDispatch } from "react-redux";
import {
  addChatHistory,
  receiveMessage,
  sendMessage,
  setCurrentChatLog,
} from "../../reducers/chat-reducer";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForward";
import { ChatLog, ChatLogMessage } from "../../const/storage";
import ChatMessage from "./chat-message";
import DateDivider from "./date-divider";
import { useInView } from "react-intersection-observer";
import { useSmallScreenQuery } from "../../util";

interface ChatInputSlotProps {
  input: {
    className: string;
    style: React.CSSProperties;
    endAdornment?: React.ReactNode;
  };
}

const Chat = () => {
  const theme = useTheme();
  const { chatInputStyle, chatInputSlotProps } = getStyles(theme);
  const { chatService } = useServices();
  const dispatch = useDispatch();
  const username = useSelector((state: RootState) => state.user.username);
  const chatState = useSelector((state: RootState) => state.chat);

  const [query, setQuery] = useState("");
  const [queryPending, setQueryPending] = useState(false);

  // Send query on enter and prevent the default 'new line' behavior.
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (query) {
        onSubmitQuery();
      }
    }
  };

  // Send message and display response.
  const onSubmitQuery = () => {
    setQueryPending(true);
    setQuery("");

    dispatch(sendMessage(query));

    // If first message of the day, create new chat log entry first.
    let chatLogIdPromise = Promise.resolve(chatState.currentChatLog.id);
    if (!chatState.currentChatLog.id) {
      chatLogIdPromise = chatService
        .createNewChatLog(username)
        .then((createResponse: CreateNewChatLogResponse) => {
          dispatch(setCurrentChatLog(createResponse.chatLog));
          // This response will overwrite the chat state, dispatch the first
          // message again.
          dispatch(sendMessage(query));
          return createResponse.chatLog.id;
        });
    }

    chatLogIdPromise.then((chatLogId: string) =>
      chatService
        .sendChatMessage(username, chatLogId, query)
        .then((response: SendChatMessageResponse) => {
          dispatch(receiveMessage(response.timestamp, response.message));
        })
        .catch(() => {
          setChatError(
            "There was a problem sending your message. Please try again later."
          );
        })
        .finally(() => {
          setQueryPending(false);
        })
    );
  };

  useEffect(() => {
    setChatError("");
  }, [query]);

  // The submit button.
  const [chatError, setChatError] = useState("");
  chatInputSlotProps.input.endAdornment = (
    <InputAdornment position="end">
      <IconButton color="primary" onClick={onSubmitQuery} disabled={!query}>
        {queryPending ? (
          <CircularProgress size={20} />
        ) : (
          <ArrowForwardOutlinedIcon />
        )}
      </IconButton>
    </InputAdornment>
  );

  // Message history.
  const chatLogDateString = (chatLog: ChatLog) => {
    // Chat log dates are saved on UTC time.
    return new Date(chatLog.date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  // Load more when scrolled to the top.
  // NOTE: Container is rendering in reverse (flex-column-reverse), so position
  // 0 is the bottom of the container.
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [noMoreChatHistory, setNoMoreChatHistory] = useState(false);
  const [chatHistoryError, setChatHistoryError] = useState("");

  const [loadMoreRef, loadMoreInView] = useInView({
    threshold: 0,

    // Track isVisible instead of isIntersecting. Had issues getting
    // isIntersecting to work.
    trackVisibility: true,
    delay: 100,
  });

  useEffect(() => {
    if (loadMoreInView && !noMoreChatHistory) {
      setIsLoadingMore(true);

      setChatHistoryError("");
      let currentChatLogId = chatState.currentChatLog.id;
      if (chatState.chatHistory.length > 0) {
        currentChatLogId = chatState.chatHistory[0].id;
      }

      // Occurs for first time users with no chat history on their first
      // chat of the day.
      if (!currentChatLogId) {
        setIsLoadingMore(false);
        return;
      }

      chatService
        .getPreviousChatLog(username, currentChatLogId)
        .then((response: GetPreviousChatLogResponse) => {
          if (response.chatLog) {
            dispatch(addChatHistory(response.chatLog));
          } else {
            setNoMoreChatHistory(true);
          }
        })
        .catch(() => {
          setChatHistoryError(
            "There was a problem loading your previous chat history."
          );
        })
        .finally(() => {
          setIsLoadingMore(false);
        });
    }
  }, [loadMoreInView]);

  // Make sure to scroll to the bottom when user sends a message and also when
  // the bot replies.
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [chatState.currentChatLog]);

  const isSmallScreen = useSmallScreenQuery();
  const containerClassNames =
    "flex flex-column " +
    (isSmallScreen ? "ma1 w-100" : "walkthrough-2 mb3 mh4 mt4 w-50");

  return (
    <div className={containerClassNames}>
      <div
        ref={containerRef}
        className="ph3 pv2 flex-grow-1 overflow-scroll flex flex-column-reverse"
      >
        {renderChatLog(chatState.currentChatLog)}

        <DateDivider dateString="Today"></DateDivider>

        {chatState.chatHistory
          .filter((chatLog: ChatLog) => chatLog.messages.length > 0)
          .slice()
          .reverse()
          .map((chatLog: ChatLog) => [
            renderChatLog(chatLog),
            <DateDivider
              key={`${chatLog.id}-divider`}
              dateString={chatLogDateString(chatLog)}
            ></DateDivider>,
          ])}

        {isLoadingMore && (
          <Box className="flex items-center justify-center mv3">
            <Typography
              variant="caption"
              color={theme.palette.primary.contrastText}
            >
              Loading more...
            </Typography>
            <CircularProgress
              size={18}
              className="ml2"
              sx={{ color: theme.palette.primary.contrastText }}
            />
          </Box>
        )}

        <div ref={loadMoreRef} />

        {chatHistoryError && (
          <Typography
            variant="subtitle2"
            color={theme.palette.error.main}
            sx={{ my: "2rem" }}
            align="center"
          >
            {chatHistoryError}
          </Typography>
        )}
      </div>

      <div
        className={
          "ph3 flex flex-column items-center " + (isSmallScreen ? "pb2" : "")
        }
      >
        <TextField
          placeholder="Chat with Dewey"
          className={isSmallScreen ? "w-70" : "w-100"}
          multiline
          maxRows={5}
          sx={chatInputStyle}
          slotProps={chatInputSlotProps}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={onKeyDown}
          error={chatError !== ""}
        />
        {chatError !== "" && (
          <Typography variant="subtitle2" className="dark-red pl3">
            {chatError}
          </Typography>
        )}
      </div>
    </div>
  );
};

const renderChatLog = (chatLog: ChatLog) => {
  return chatLog.messages
    .slice()
    .reverse()
    .map((message: ChatLogMessage) => (
      <ChatMessage key={message.timestamp} message={message} />
    ));
};

const getStyles = (theme: Theme) => ({
  chatInputStyle: {
    borderRadius: "2rem",
    "& .MuiOutlinedInput-root": {
      borderRadius: "2rem",
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.light,
      },
    },
    backgroundColor: theme.palette.secondary.light,
  },
  chatInputSlotProps: {
    input: {
      style: {
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        paddingRight: "4px",
      },
    },
  } as ChatInputSlotProps,
});

export default Chat;
